.actions-icons {
    display: flex;
    gap: 0.75rem;
}

.table-toolbar{
    flex-wrap: wrap;
    gap:0.75rem;
}

.question-column{
    width:85%;
}

.student-response-column{
    width:5%;
    text-align: center;
}

.answer-column{
    width:10%;
    text-align: right;
}

.email-column{
    width:50%;
}

.button-column{
    width:50%;
    text-align: right;
}

@media (max-width: 540px) {
    .question-column{
        width:70%;
    }

    .answer-column{
        width:30%;
        text-align: right;
    }
}