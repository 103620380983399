.menu-wrapper {
    display: flex;
}

.items-list {
    margin: 1.5rem 1.5rem 0 1.5rem!important;
}

.list-item {
    text-decoration: none;
}

.list-item-last{
    border-top:1px solid var(--joy-palette-primary-400)!important;
    padding-top:1rem!important;
}

.list-item:hover{
    background-color: var(--joy-palette-neutral-100);
}

.bottom-wrapper {
    position:fixed;
    bottom:2rem;
    width:100%;
    display: flex;
    justify-content: center;
}

.new-course-btn {
    width: 85%;
    margin-top: 1rem;
}