.card-separator {
    border-left: 1px solid var(--joy-palette-divider);
    padding-left:1.5rem!important;
    box-sizing: border-box!important;
}

@media (max-width: 540px) {
    .card-separator {
        border-left: 0px;
        border-top: 1px solid var(--joy-palette-divider);
        padding-left:0!important;
        margin-top:1.5rem!important;;
        padding-top: 1.5rem!important;
        box-sizing: border-box!important;
    }
}

