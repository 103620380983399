body {
  margin: 0;
  font-family: 'Lora',
    sans-serif;
  background-color: var(--joy-palette-neutral-50);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Lora';
    src: local('Lora'),
         url('./fonts/Lora-VariableFont_wght.ttf') format('truetype');
}
@font-face {
    font-family: 'UbuntuMedium';
    src: local('UbuntuMedium'),
         url('./fonts/Ubuntu-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'UbuntuRegular';
    src: local('UbuntuRegular'),
         url('./fonts/Ubuntu-Regular.ttf') format('truetype');
}
