.header-wrapper, .min-header-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1.5rem 3rem;
    min-height: 8vh;
    position: sticky;
    top: 0; 
    background-image: linear-gradient(to bottom, 
    rgba(252, 252, 248, 1) 0%,
    rgba(252, 252, 248, 0.9) 80%,
    rgba(252, 252, 248, 0) 100%);
    z-index: 1000; 
}

.left-wrapper {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
}

.student-left-wrapper{
    align-items: center;
}

.logo {
    height: 50px;
    margin-right:1.5rem;
}

.logo-mobile{
    display: none;
}

.right-wrapper {
    display: flex;
    align-items: flex-start;
    gap:0.5rem;
}

.class-selection button{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

@media (max-width: 1240px) {
    .header-wrapper, .min-header-wrapper {
        padding: 1.5rem;
    }
}

@media (max-width: 768px) {
    .logo {
        display: none;
    }
    .logo-mobile{
        display: block;
        height: 55px;
        margin-right:1rem;
    }
}


@media (min-width: 768px) {
    .header-wrapper, .min-header-wrapper {
        padding: 1.5rem;
    }
}

@media (max-width: 480px) {
    .header-wrapper {
        padding: 1rem;
        flex-wrap:wrap;
    }

    .left-wrapper {
        width:100%!important;
        justify-content: space-between;
        align-items: center;
    }

    .right-wrapper {
        justify-content: left;
        align-items: center;
        width:100%;
        margin-top:1rem;
    }

    .right-wrapper div{
        width:100%;
    }

    .min-header-wrapper {
        padding: 1rem;
    }

    .min-header-wrapper .left-wrapper{
        justify-content: flex-start;
    }

    .min-header-wrapper .right-wrapper{
        margin-top:0;
    }


    .logo, .hide-mobile {
        display: none!important;
    
    }
    .logo-mobile{
        display: block;
        height: 55px;
    }
}
