.modal-container{
	padding:4rem!important;
}

.modal-name{
	font-size:2.25rem!important;
}

.dialog-actions-right {
	flex-direction: row!important;
	display:flex!important;
  	justify-content: flex-end;
}

.dialog-actions-right button {
  	width: auto;
  	flex-grow: 0!important; 
}

.scrollable-box {
  /* Base styles */
  max-height: 50vh;
  width: 100%;
  overflow-y: scroll;

  /* Firefox scrollbar */
  scrollbar-width: thin;
  scrollbar-color: var(--joy-palette-primary-400) var(--joy-palette-background-level1);

  /* Focus styles */
  &:focus {
    outline: 2px solid var(--joy-palette-primary-500);
    outline-offset: 2px;
  }

  /* Webkit scrollbar styles (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 8px;
    background-color: var(--joy-palette-background-level1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--joy-palette-primary-400);
    border-radius: 4px;
  }
}


@media (max-width: 768px) {
	.modal-container{
		padding:2rem!important;
	}
}

@media (max-width: 480px) {
	.modal-container{
		padding:2rem!important;
	}
	.modal-name{
		font-size:1.5rem!important;
	}
}