.main-container {
    max-width: 1240px;
    min-height: 100vh;
    margin: 0 auto;
    margin-top: 3rem;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .main-container {
        padding: 0 1.5rem;
        margin-top: 1.5rem;
    }
}

@media (max-width: 480px) {
    .main-container {
        padding: 0 1rem;
        margin-top: 1.5rem;
    }
}
