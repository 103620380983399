.tip-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: white;
    margin-top: 1.5rem;
    height: auto;
    border-radius: var(--joy-radius-lg);
    box-shadow: var(--joy-shadow-md);
    padding: 1.5rem;
}

.flex-header{
    display: flex;
    width:100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.tip-item-container {
    display: flex;
    justify-content: center;
}

.tip-item {
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 0 0.75rem;
    text-align: left;
}

.grey-box {
    height: 24px;
    width: 24px;
    display:flex;
    align-items: center;
    justify-content: center;
}
