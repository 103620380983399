.drag-drop-container {
    border: 1px dashed var(--joy-palette-primary-200);
    width: 100%;
    box-sizing: border-box;
    margin: 1.5rem 0;
    padding: 3rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    text-align: center;
}

.drag-drop-container > div{
    text-align: center;
}

.drag-drop-container.disabled {
    background-color: var(--joy-palette-neutral-200);
    border: transparent;
}
